import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { Badge, ListGroup } from 'react-bootstrap';
import { handleInputChange } from './_helpers/form/handleInputChange';
import { CopyBlock, atomOneLight } from "react-code-blocks";


function ReleaseBug() {

    const [branchName, setBranchName] = useState('');
    const [releaseVersion, setReleaseVersion] = useState({ versionIndex: '', release: '' });
    const [branch, setBranch] = useState({ developer: '', branch_type: 'hotfix', ticket: '', title: '' });
    const [message, setMessage] = useState('');

    /**
    * function to handle input changes and modify the value
    * @param {string} e.name input name
    * @param {string} e.value input value
    * @author Akshay N
    */
    function handleChange(e) {
        var { name, value } = handleInputChange(e);

        if (name === 'title') {
            value = value.replace(/\s+/g, '-').toLowerCase();
            value = value.replace(/\//g, '-').toLowerCase();
            value = value.replace(/_/g, '-').toLowerCase();
        }

        branch[name] = value;
        setBranch({ ...branch });

        if (branch.developer && branch.branch_type && branch.ticket)
            setBranchName(`${branch.developer}/${branch.branch_type}/${branch.ticket}` + ((branch.title) ? `/${branch.title}` : ''));
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     */
    function handleVersionChange(e) {
        var { value } = handleInputChange(e);
        setReleaseVersion({ ...releaseVersion, versionIndex: value, release: `release-v${value}` });
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     */
    function handleMessageChange(e) {
        var { value } = handleInputChange(e);

        // value = value.replace(/\s+/g, '-').toLowerCase();
        value = value.replace(/\//g, '-');
        value = value.replace(/_/g, '-');

        setMessage(value);

    }

    return (
        <React.Fragment>
            <ListGroup as="ol" numbered>
                <ListGroup.Item as="li" className="d-flex align-items-start b-none">
                    <Row className="ms-2 w-100">
                        <Col>
                            <h6>Create a hot fix branch based off of master.</h6>
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout master \ngit pull origin master "}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout dev-master \ngit pull origin dev-master \ngit rebase master"}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout feature-master \ngit pull origin feature-master \ngit rebase master"}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout test-master \ngit pull origin test-master \ngit rebase master"}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout preview-master \ngit pull origin preview-master \ngit rebase master"}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li" className="d-flex align-items-start b-none">
                    <Row className="ms-2 w-100">
                        <Col>
                            <div className="fw-bold  mb-2">Create a new branch</div>
                            <InputGroup>
                                <Form.Select name='developer' onChange={handleChange}>
                                    <option>Select Developer</option>
                                    <option value="akshay">Akshay N</option>
                                    <option value="drishya">Drishya K Chandran</option>
                                    <option value="jasin">Jasin C</option>
                                    <option value="jibi">Jibi Gopi</option>
                                    <option value="naveen">Naveen K</option>
                                    <option value="rafi">Muhammed Rafi</option>
                                    <option value="sanith">Sanith A</option>
                                    <option value="libin">Libin Kurian</option>
                                </Form.Select>
                                <InputGroup.Text id="basic-addon3">Hotfix</InputGroup.Text>
                                <Form.Control placeholder="Enter Ticket Number" name='ticket' onChange={handleChange} />
                                <Form.Control placeholder="Enter Branch Title" name='title' onChange={handleChange} style={{ width: `30%` }} />
                            </InputGroup>
                            <div className={(!branchName) ? 'not-available' : ''}>
                                <CopyBlock
                                    language={"c"}
                                    // eslint-disable-next-line no-useless-escape
                                    text={branchName ? `git checkout -b \"${branchName}\"` : `// Branch name form not completed`}
                                    showLineNumbers={false}
                                    theme={atomOneLight}
                                    wrapLines={true}
                                    codeBlock
                                />
                            </div>
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li" className={`d-flex align-items-start b-none ${(!branchName) ? 'not-available' : ''}`}>
                    <Row className="ms-2 w-100">
                        <Col>
                            <div className="fw-bold mb-2">... Fix the bugs and test</div>
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li" className={`d-flex align-items-start b-none ${(!branchName) ? 'not-available' : ''}`}>
                    <Row className="ms-2 w-100">
                        <Col>
                            <div className="fw-bold mb-2">Commit current changes</div>
                            <InputGroup>
                                <InputGroup.Text>Commit Message</InputGroup.Text>
                                <Form.Control placeholder="Enter Message" name='message' value={message} onChange={handleMessageChange} />
                            </InputGroup>
                            <div className={`${(!message) ? 'not-available' : ''}`}>
                                <CopyBlock
                                    language={"c"}
                                    // eslint-disable-next-line no-useless-escape
                                    text={message ? `git add . \ngit commit -m \"${message}\" ` : `git add . \n// message not added `}
                                    showLineNumbers={false}
                                    theme={atomOneLight}
                                    wrapLines={true}
                                    codeBlock
                                />
                            </div>
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li" className={`d-flex align-items-start b-none ${(!branchName || !message) ? 'not-available' : ''}`}>
                    <Row className="ms-2 w-100">
                        <Col>
                            <div className="fw-bold mb-2">Push current changes to remote repository</div>
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={`git push origin \"${branchName}\"`}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li" className={`d-flex align-items-start b-none  ${(!branchName || !message) ? 'not-available' : ''}`}>
                    <Row className="ms-2 w-100">
                        <Col>
                            <div className="fw-bold mb-2">Navigate to the project on Github and open a pull request with the following branch settings</div>
                            <Row>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Text>Base</InputGroup.Text>
                                        <Form.Control value={`master`} readOnly />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Text>Compare</InputGroup.Text>
                                        <Form.Control value={branchName} readOnly />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li" className={`d-flex align-items-start b-none  ${(!branchName || !message) ? 'not-available' : ''}`}>
                    <Row className="ms-2 w-100">
                        <Col>
                            <h6>At some point in the checklist you will merge the release branch into master. You can do this by using the <Badge bg="success">Merge pull request</Badge> button on the release PR.</h6>
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li" className={`d-flex align-items-start b-none  ${(!branchName || !message) ? 'not-available' : ''}`}>
                    <Row className="ms-2 w-100">
                        <Col xs={12}>
                            <h6>Now that the hotfix code is in master you are ready to create the actual release. Navigate to the project page on Github and draft a new release with the following settings:</h6>
                        </Col>
                        <Col xs={12} className="ms-2 w-100">
                            <InputGroup className="mt-2">
                                <InputGroup.Text>Release Version</InputGroup.Text>
                                <Form.Control placeholder="1.0.0" name='release_version' value={releaseVersion.versionIndex} onChange={handleVersionChange} />
                            </InputGroup>
                        </Col>
                        <Col xs={12}>
                            <ListGroup as="ol" numbered>
                                <ListGroup.Item as="li" className="d-flex align-items-start b-none"><span className="ms-2 w-100">Tag version: <Badge bg="light" text="dark">{`v${releaseVersion.versionIndex}`}</Badge></span></ListGroup.Item>
                                <ListGroup.Item as="li" className="d-flex align-items-start b-none"><span className="ms-2 w-100">Target: <Badge bg="light" text="dark"> master</Badge></span></ListGroup.Item>
                                <ListGroup.Item as="li" className="d-flex align-items-start b-none"><span className="ms-2 w-100">Release title:<Badge bg="light" text="dark"> Release {`v${releaseVersion.versionIndex}`}</Badge></span></ListGroup.Item>
                                <ListGroup.Item as="li" className="d-flex align-items-start b-none"><span className="ms-2 w-100">Description: Include a high-level list of things changed in this release. Click <Badge bg="success">Publish</Badge> release.</span></ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li" className={`d-flex align-items-start b-none  ${(!releaseVersion.versionIndex) ? 'not-available' : ''}`}>
                    <Row className="ms-2 w-100">
                        <Col>
                            <h6>Merge master into develop and test master</h6>
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout master \ngit pull origin master "}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout dev-master \ngit pull origin dev-master \ngit rebase master"}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout test-master \ngit pull origin test-master \ngit rebase master"}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                        </Col>
                    </Row>
                </ListGroup.Item>
            </ListGroup>
        </React.Fragment>
    );
}

export { ReleaseBug };