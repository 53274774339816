import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { Badge, ListGroup } from 'react-bootstrap';
import { handleInputChange } from './_helpers/form/handleInputChange';
import { CopyBlock, atomOneLight } from "react-code-blocks";

function ReleaseFeature() {

    const [releaseVersion, setReleaseVersion] = useState({ versionIndex: '', release: '' });
    const [message, setMessage] = useState('');

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     */
    function handleVersionChange(e) {
        var { value } = handleInputChange(e);
        setReleaseVersion({ ...releaseVersion, versionIndex: value, release: `release-v${value}` });
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     */
    function handleMessageChange(e) {
        var { value } = handleInputChange(e);

        // value = value.replace(/\s+/g, '-').toLowerCase();
        value = value.replace(/\//g, '-');
        value = value.replace(/_/g, '-');

        setMessage(value);

    }

    return (
        <React.Fragment>
            <ListGroup as="ol" numbered>
                <ListGroup.Item as="li" className="d-flex align-items-start b-none">
                    <Row className="ms-2 w-100">
                        <Col>
                            <h6>Merge master into develop to ensure the new release will contain the latest production code. This reduces the chance of a merge conflict during the release.</h6>
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout master \ngit pull origin master "}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout dev-master \ngit pull origin dev-master \ngit rebase master"}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout feature-master \ngit pull origin feature-master \ngit rebase master"}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout test-master \ngit pull origin test-master \ngit rebase master"}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git checkout preview-master \ngit pull origin preview-master \ngit rebase master"}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li" className="d-flex align-items-start b-none">
                    <Row className="ms-2 w-100">
                        <Col>
                            <h6>Create a release branch</h6>
                            <Row>
                                <Col xs={4}>
                                    <InputGroup className="mt-2">
                                        <InputGroup.Text>Release Version</InputGroup.Text>
                                        <Form.Control placeholder="1.0.0" name='release_version' value={releaseVersion.versionIndex} onChange={handleVersionChange} />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup className="mt-2">
                                        <InputGroup.Text>Commit Message</InputGroup.Text>
                                        <Form.Control placeholder="Enter Message" name='message' value={message} onChange={handleMessageChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <div className={(!releaseVersion.release || !message) ? 'not-available' : ''}>
                                <CopyBlock
                                    language={"c"}
                                    // eslint-disable-next-line no-useless-escape
                                    text={(message && releaseVersion.release) ? `git checkout -b ${releaseVersion.release} \ngit commit -m \"${message}\" \ngit push origin ${releaseVersion.release}` : `// Branch not created \n// Message not added \n// Branch not created`}
                                    showLineNumbers={false}
                                    theme={atomOneLight}
                                    wrapLines={true}
                                    codeBlock
                                />
                            </div>
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li" className={`d-flex align-items-start b-none  ${(!releaseVersion.versionIndex || !message) ? 'not-available' : ''}`}>
                    <Row className="ms-2 w-100">
                        <Col>
                            <h6>When the code is ready to release, navigate to the project on Github and open a pull request with the following branch settings:</h6>
                            <Row>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Text>Base</InputGroup.Text>
                                        <Form.Control value={`master`} readOnly />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Text>Compare</InputGroup.Text>
                                        <Form.Control value={releaseVersion.release} readOnly />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li" className={`d-flex align-items-start b-none  ${(!releaseVersion.versionIndex || !message) ? 'not-available' : ''}`}>
                    <Row className="ms-2 w-100">
                        <Col>
                            <h6>At some point in the checklist you will merge the release branch into master. You can do this by using the <Badge bg="success">Merge pull request</Badge> button on the release PR.</h6>
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li" className={`d-flex align-items-start b-none  ${(!releaseVersion.versionIndex || !message) ? 'not-available' : ''}`}>
                    <Row className="ms-2 w-100">
                        <Col>
                            <h6>Now you are ready to create the actual release. Navigate to the project page on Github and draft a new release with the following settings:</h6>
                        </Col>
                        <Row>
                            <Col>
                                <ListGroup numbered>
                                    <ListGroup.Item as="li" className="d-flex align-items-start b-none"><span className="ms-2 w-100">Tag version: <Badge bg="light" text="dark">{`v${releaseVersion.versionIndex}`}</Badge></span></ListGroup.Item>
                                    <ListGroup.Item as="li" className="d-flex align-items-start b-none"><span className="ms-2 w-100">Target: <Badge bg="light" text="dark"> master</Badge></span></ListGroup.Item>
                                    <ListGroup.Item as="li" className="d-flex align-items-start b-none"><span className="ms-2 w-100">Release title:<Badge bg="light" text="dark"> Release {`v${releaseVersion.versionIndex}`}</Badge></span></ListGroup.Item>
                                    <ListGroup.Item as="li" className="d-flex align-items-start b-none"><span className="ms-2 w-100">Description: Include a high-level list of things changed in this release. Click <Badge bg="success">Publish</Badge> release.</span></ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                    </Row>
                </ListGroup.Item>
            </ListGroup>
        </React.Fragment>
    );
}

export { ReleaseFeature };