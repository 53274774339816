import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import { ListGroup } from 'react-bootstrap';
import { handleInputChange } from './_helpers/form/handleInputChange';
import { CopyBlock, atomOneLight } from 'react-code-blocks';

function NewFeature() {
	const [branchName, setBranchName] = useState('');
	const [message, setMessage] = useState('');
	const [branch, setBranch] = useState({ developer: '', branch_type: '', ticket: '', title: '' });

	/**
	 * function to handle input changes and modify the value
	 * @param {string} e.name input name
	 * @param {string} e.value input value
	 * @author Akshay N
	 */
	function handleChange(e) {
		var { name, value } = handleInputChange(e);

		if (name === 'title') {
			value = value.replace(/\s+/g, '-').toLowerCase();
			value = value.replace(/\//g, '-').toLowerCase();
			value = value.replace(/_/g, '-').toLowerCase();
		}
		if (name === 'ticket') {
			value = value.replace(/\s+/g, '-').toLowerCase();
		}

		branch[name] = value;
		setBranch({ ...branch });

		if (branch.developer && branch.branch_type && branch.ticket) setBranchName(`${branch.developer}/${branch.branch_type}/${branch.ticket}` + (branch.title ? `/${branch.title}` : ''));
	}

	/**
	 * function to handle input changes and modify the value
	 * @param {string} e.name input name
	 * @param {string} e.value input value
	 * @author Akshay N
	 */
	function handleMessageChange(e) {
		var { value } = handleInputChange(e);

		// value = value.replace(/\s+/g, '-').toLowerCase();
		value = value.replace(/\//g, '-');
		value = value.replace(/_/g, '-');

		setMessage(value);
	}

	return (
		<React.Fragment>
			<Card className="mb-3">
				<Card.Body>
					<ListGroup as="ol" numbered>
						<ListGroup.Item as="li" className="d-flex align-items-start b-none">
							<Row className="ms-2 w-100">
								<Col>
									<div className="fw-bold mb-2">Delete all local branches that are already merged</div>
									<h6 className="mt-4">Delete all local remote branches already merged</h6>
									<div>
										<CopyBlock
											language={'c'}
											// eslint-disable-next-line no-useless-escape
											text={`git branch --merged | egrep -v "(^\\*|dev-master|feature-master|test-master|preview-master|master)" | xargs git branch -d \ngit remote prune origin`}
											showLineNumbers={false}
											theme={atomOneLight}
											wrapLines={true}
											codeBlock
										/>
									</div>
									<h6 className="mt-2">Delete all local branches other than dev-master,feature-master, test-master, preview-master and master</h6>
									<div>
										<CopyBlock
											language={'c'}
											// eslint-disable-next-line no-useless-escape
											text={`git branch | grep -v "dev-master\\|feature-master\\|test-master\\|preview-master\\|master" | xargs git branch -D`}
											showLineNumbers={false}
											theme={atomOneLight}
											wrapLines={true}
											codeBlock
										/>
									</div>
								</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item as="li" className="d-flex align-items-start b-none">
							<Row className="ms-2 w-100">
								<Col>
									<div className="fw-bold mb-2">Remove all tags</div>
									<h6 className="mt-4">To remove all Git local tags and fetch tags.</h6>
									<div>
										<CopyBlock
											language={'c'}
											// eslint-disable-next-line no-useless-escape
											text={`git tag -l | xargs git tag -d \ngit fetch --tags`}
											showLineNumbers={false}
											theme={atomOneLight}
											wrapLines={true}
											codeBlock
										/>
									</div>
								</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item as="li" className="d-flex align-items-start b-none">
							<Row className="ms-2 w-100">
								<Col>
									<div className="fw-bold mb-2">Fetch and download content from a remote repository and immediately update the local repository to match that content</div>
									<CopyBlock language={'c'} text={'git checkout dev-master \ngit pull origin dev-master'} showLineNumbers={false} theme={atomOneLight} wrapLines={true} codeBlock />
								</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item as="li" className="d-flex align-items-start b-none">
							<Row className="ms-2 w-100">
								<Col>
									<div className="fw-bold  mb-2">Create a new branch</div>
									<InputGroup>
										<Form.Select name="developer" onChange={handleChange}>
											<option>Select Developer</option>
											<option value="akshay">Akshay N</option>
											<option value="drishya">Drishya K Chandran</option>
											<option value="jasin">Jasin C</option>
											<option value="jibi">Jibi Gopi</option>
											<option value="rafi">Muhammed Rafi</option>
											<option value="naveen">Naveen K</option>
											<option value="sanith">Sanith A</option>
											<option value="sreejith">Sreejith K R</option>
										</Form.Select>
										<Form.Select name="branch_type" onChange={handleChange}>
											<option>Select Branch Type</option>
											<option value="feature">Feature</option>
											<option value="release">Release</option>
											<option value="hotfix">Hotfix</option>
										</Form.Select>
										<Form.Control placeholder="Enter Ticket Number" name="ticket" onChange={handleChange} />
										<Form.Control placeholder="Enter Branch Title" name="title" onChange={handleChange} style={{ width: `30%` }} />
									</InputGroup>
									<div className={`${!branchName ? 'not-available' : ''} mt-2`}>
										<CopyBlock
											language={'c'}
											// eslint-disable-next-line no-useless-escape
											text={branchName ? `git checkout -b \"${branchName}\"` : `// Branch name form not completed`}
											showLineNumbers={false}
											theme={atomOneLight}
											wrapLines={true}
											codeBlock
										/>
									</div>
								</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item as="li" className={`d-flex align-items-start b-none ${!branchName ? 'not-available' : ''}`}>
							<Row className="ms-2 w-100">
								<Col>
									<div className="fw-bold mb-2">... do work</div>
								</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item as="li" className={`d-flex align-items-start b-none ${!branchName ? 'not-available' : ''}`}>
							<Row className="ms-2 w-100">
								<Col>
									<div className="fw-bold mb-2">Commit current changes</div>
									<InputGroup>
										<InputGroup.Text>Commit Message</InputGroup.Text>
										<Form.Control placeholder="Enter Message" name="message" value={message} onChange={handleMessageChange} />
									</InputGroup>
									<div className={`${!message ? 'not-available' : ''}`}>
										<CopyBlock
											language={'c'}
											// eslint-disable-next-line no-useless-escape
											text={message ? `git add . \ngit commit -m \"${message}\" ` : `git add . \n// message not added `}
											showLineNumbers={false}
											theme={atomOneLight}
											wrapLines={true}
											codeBlock
										/>
									</div>
								</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item as="li" className={`d-flex align-items-start b-none ${!branchName || !message ? 'not-available' : ''}`}>
							<Row className="ms-2 w-100">
								<Col>
									<div className="fw-bold mb-2">Push current changes to remote repository</div>
									<CopyBlock
										language={'c'}
										// eslint-disable-next-line no-useless-escape
										text={`git push origin \"${branchName}\"`}
										showLineNumbers={false}
										theme={atomOneLight}
										wrapLines={true}
										codeBlock
									/>
								</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item as="li" className={`d-flex align-items-start b-none  ${!branchName || !message ? 'not-available' : ''}`}>
							<Row className="ms-2 w-100">
								<Col>
									<div className="fw-bold mb-2">Navigate to the project on Github and open a pull request with the following branch settings</div>
									<Row>
										<Col>
											<InputGroup>
												<InputGroup.Text>Base</InputGroup.Text>
												<Form.Control value={`dev-master`} readOnly />
											</InputGroup>
										</Col>
										<Col>
											<InputGroup>
												<InputGroup.Text>Compare</InputGroup.Text>
												<Form.Control value={branchName} readOnly />
											</InputGroup>
										</Col>
									</Row>
								</Col>
							</Row>
						</ListGroup.Item>
					</ListGroup>
				</Card.Body>
			</Card>
		</React.Fragment>
	);
}

export { NewFeature };
